import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components"

const IndexPage = ({
  data: {
    prismicPage: {
      data: {
        background: {
          localFile
        },
        title,
        description,
        ogimage
      }
    }
  }
}) => {
  return (
    <Layout 
      context={{
        title: title.text,
        description: description.text,
        ogimage: ogimage.localFile.publicURL,
        slug: '/'
      }}
      background={localFile}
    ></Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query homePage {
    prismicPage(uid: { eq: "home" }) {
      uid
      data {
        background {
          localFile {
            publicURL
          }
        }
        title {
          text
        }
        ogimage {
          localFile {
            publicURL
          }
        }
        description {
          text
        }
      }
    }
  }
`